import { Outlet } from "react-router-dom"

const NoPage = () => {

    return (
        <>
            Contact

            <Outlet />
        </>
    )
}

export default NoPage