import { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import Footer from "../Footer/Footer";
import "./Rdv.css";

const Rdv = () => {

    const [startDate, setStartDate] = useState(new Date());

    return (
        <>
            <div className="rdv-main">
                {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect /> */}
                <div className="rdv-info-wrapper">
                    <p className="rdv-info">
                        Bientôt la possibilité de prendre les rdv directement sur le site
                    </p>
                    <p className="rdv-info">
                        Contact sur WhatsApp (pour les questions-réponses / prises de rdv)
                        <br />
                        -Les séances sont en télé-consultation
                        <br />
                        -Le premier rdv dure 50 mn
                        <br />
                        -Le prix basique pour les étudiants est de 20 à 30 euros par séance.
                    </p>
                </div>

                <div className="square-one rdv-shape"></div>
                <div className="square-two rdv-shape"></div>
                <div className="square-three rdv-shape"></div>
                <div className="square-four rdv-shape"></div>


                <div className="circle-one rdv-shape"></div>
                <div className="circle-two rdv-shape"></div>
                <div className="circle-three rdv-shape"></div>
                <div className="circle-four rdv-shape"></div>


                <div className="triangle-one rdv-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-two rdv-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-three rdv-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-four rdv-shape">
                    <div className="triangle-empty"></div>
                </div>

            </div>
            <Footer />
        </>

    )
}

export default Rdv