import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from 'react';

import HomePage from './pages/HomePage';
import Layout from './pages/Layout';
import ContactPage from './pages/ContactPage';
import NoPage from './pages/NoPage';
import RdvPage from './pages/RdvPage';
import AboutPage from './pages/AboutPage';

function App() {
  const [selectedLang, setSelectedLang] = useState("pr");

  const changeSelectLangHandler = () => {
    if (selectedLang === "fr") {
      setSelectedLang("pr");
    } else {
      setSelectedLang("fr");
    }
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout selectedLang={selectedLang} changeSelectLangHandler={changeSelectLangHandler} />}>
          <Route index element={<HomePage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='rdv' element={<RdvPage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='*' element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
