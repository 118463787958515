import { Link } from "react-router-dom";
import "./Header.css";

import franceLogo from "../../assets/france-logo.png";
import iranLogo from "../../assets/iran-logo.png"

const Header = (props) => {
    const { selectedLang, changeSelectLangHandler } = props;

    const logo = selectedLang === "fr" ? franceLogo : iranLogo;
    const logoClassName = selectedLang === "fr" ? "lang-logo" : "lang-logo iran-logo"

    return (
        <div className="navbar">
            <div className="nav-title">
                <Link className="link" to="/">
                    <h2 className="nav-title-text">
                        Niyousha Masudy
                    </h2>
                </Link>

            </div>
            <div className="nav-links">

                <div className="nav-link">
                    <Link className="link" to="contact">Contact</Link>
                </div>
                <div className="nav-link">
                    <Link className="link" to="rdv">RDV</Link>
                </div>
                <div className="nav-link">
                    <Link className="link" to="about">A propos</Link>
                </div>

                {/* <div className="lang-logo-wrapper">
                    <img className="lang-logo" src={franceLogo}></img>
                </div> */}

                <div className="lang-logo-wrapper">
                    <img className={logoClassName} src={logo} onClick={changeSelectLangHandler()}></img>
                </div>

            </div>
        </div>
    )
}

export default Header