import "./Footer.css";

import instaLogo from "../../assets/insta-logo.jpg"

const Footer = () => {

    return (
        <>
            <div className="footer">
                <div className="rights-wrapper">
                    <h3>
                        2024, all rights reserved
                    </h3>
                </div>
                <div className="footer-links-wrapper">
                    <img className="footer-logo" src={instaLogo} />
                </div>
            </div>
        </>
    )
}

export default Footer