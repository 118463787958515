import Home from "../components/Home/Home";

const HomePage = () => {

    return (

        <Home />


    )
}

export default HomePage