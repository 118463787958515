import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";

const Layout = (props) => {

    const { selectedLang, changeSelectLangHandler } = props;

    return (
        <>
            <Header selectedLang={selectedLang} changeSelectLangHandler={() => changeSelectLangHandler} />
            <Outlet />
        </>

    )
}

export default Layout