import Footer from "../Footer/Footer";
import "./Contact.css";

const Contact = () => {

    return (
        <>
            <div className="contact-main">
                <div className="contact-info-wrapper">
                    <p className="contact-info">
                        Contact sur WhatsApp (pour les questions-réponses / prises de rdv)
                        <br />
                        -Les séances sont en télé-consultation
                        <br />
                        -Le premier rdv dure 50 mn
                        <br />
                        -Le prix basique pour les étudiants est de 20 à 30 euros par séance.
                    </p>
                </div>
                <div className="square-one contact-shape"></div>
                <div className="square-two contact-shape"></div>
                <div className="square-three contact-shape"></div>
                <div className="square-four contact-shape"></div>


                <div className="circle-one contact-shape"></div>
                <div className="circle-two contact-shape"></div>
                <div className="circle-three contact-shape"></div>
                <div className="circle-four contact-shape"></div>


                <div className="triangle-one contact-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-two contact-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-three contact-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-four contact-shape">
                    <div className="triangle-empty"></div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact