import Footer from "../Footer/Footer";
import "./About.css";

const About = () => {

    return (
        <>
            <div className="about-main">
                <div className="about-text-wrapper">
                    <p className="about-text">
                        L'analyse thérapeutique, basée sur la psychanalyse ainsi que la psychodynamique, assiste les patients afin qu'ils surmontent les difficultés psychologiques rencontrées au fil de leur vie. La perte d’une personne chère, les problèmes rencontrés au travail, le manque de motivation, les difficultés dans la vie sexuelle et/ou amoureuse, etc., 
                        demandent parfois une aide externe afin de mieux comprendre les raisons fondamentales de chaque obstacle dans la vie quotidienne. 
                        <br />
                        (#angoisse, <br/>#inhibition, <br />#difficultés_affectives, <br />#trouble_de_personnalité, <br />etc.)

                    </p>

                    <p className="about-text">
                        Le praticien s'appuie pour cela sur une méthode de questionnement et d'écoute visant à explorer l'inconscient de la personne suivie, l'aidant ainsi à mieux régler ses problèmes cognitifs et comportementaux. 
                        Pour les problèmes d'estime de soi, d'anxiété, de stress et de dépression, la thérapie analytique peut offrir une écoute attentive et neutre à la personne en souffrance
                    </p>
                </div>


                <div className="square-one about-shape"></div>
                <div className="square-two about-shape"></div>
                <div className="square-three about-shape"></div>
                <div className="square-four about-shape"></div>


                <div className="circle-one about-shape"></div>
                <div className="circle-two about-shape"></div>
                <div className="circle-three about-shape"></div>
                <div className="circle-four about-shape"></div>


                <div className="triangle-one about-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-two about-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-three about-shape">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-four about-shape">
                    <div className="triangle-empty"></div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About