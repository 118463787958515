import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./Home.css";

const Home = () => {

    return (
        <>
            <div className="home-main">
                <div className="rdv-btn-wrapper">
                    <button className="rdv-btn">
                        <Link className="rdv-link" to="rdv">Prendre un rendez-vous</Link>
                    </button>

                </div>
                {/* <div className="hexagone"></div>
                <div className="polygone"></div> */}

                <div className="square-one"></div>
                <div className="square-two"></div>
                <div className="square-three"></div>
                <div className="square-four"></div>


                <div className="circle-one"></div>
                <div className="circle-two"></div>
                <div className="circle-three"></div>
                <div className="circle-four"></div>


                <div className="triangle-one">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-two">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-three">
                    <div className="triangle-empty"></div>
                </div>
                <div className="triangle-four">
                    <div className="triangle-empty"></div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Home