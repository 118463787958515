import { Outlet } from "react-router-dom";
import Contact from "../components/Contact/Contact";

const ContactPage = () => {

    return (
        <Contact />
    )
}

export default ContactPage